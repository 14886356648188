var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          on: {
            click: function($event) {
              _vm.showModal = true
            }
          }
        },
        [
          _vm._t("default", function() {
            return [
              _vm.user && _vm.user.id
                ? _c(
                    "b-button",
                    { attrs: { variant: "success", size: "sm" } },
                    [
                      _c("b-icon", { attrs: { icon: "pencil" } }),
                      _vm._v(" Edit User ")
                    ],
                    1
                  )
                : _c(
                    "b-button",
                    { attrs: { variant: "success", size: "sm" } },
                    [
                      _c("b-icon", { attrs: { icon: "plus" } }),
                      _vm._v(" Add User ")
                    ],
                    1
                  )
            ]
          })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.user && _vm.user.id ? "Edit User" : "Add User",
            "ok-variant": "primary",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg"
          },
          on: { hidden: _vm.reset, shown: _vm.init, ok: _vm.save },
          scopedSlots: _vm._u([
            {
              key: "modal-ok",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "check" } }),
                  _vm._v(" Save ")
                ]
              },
              proxy: true
            },
            {
              key: "modal-cancel",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "x" } }),
                  _vm._v(" Cancel ")
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading } },
            [
              !_vm.user || !_vm.user.id
                ? _c(
                    "div",
                    { staticClass: "row mb-3" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "form-label text-right col-4",
                          attrs: { for: "existinguser-select" }
                        },
                        [
                          _vm._t("label", function() {
                            return [_vm._v("Search Existing Users")]
                          })
                        ],
                        2
                      ),
                      _vm.showUserSearch
                        ? _c("vue-typeahead-bootstrap", {
                            staticClass: "col pl-2",
                            attrs: {
                              data: _vm.usersList,
                              serializer: function(s) {
                                return s.fullname + " <" + s.email + ">"
                              },
                              inputName: "existinguser-select",
                              debounce: "500"
                            },
                            on: {
                              hit: function($event) {
                                return _vm.selectExisting($event)
                              }
                            },
                            model: {
                              value: _vm.userSearch.text,
                              callback: function($$v) {
                                _vm.$set(_vm.userSearch, "text", $$v)
                              },
                              expression: "userSearch.text"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "First Name",
                    "label-align": "right",
                    "label-cols": "4"
                  }
                },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.form.firstname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "firstname", $$v)
                      },
                      expression: "form.firstname"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Last Name",
                    "label-align": "right",
                    "label-cols": "4"
                  }
                },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.form.lastname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "lastname", $$v)
                      },
                      expression: "form.lastname"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Email",
                    "label-align": "right",
                    "label-cols": "4"
                  }
                },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Phone",
                    "label-align": "right",
                    "label-cols": "4"
                  }
                },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.form.phonenumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phonenumber", $$v)
                      },
                      expression: "form.phonenumber"
                    }
                  })
                ],
                1
              ),
              !+_vm.organizationId &&
              _vm.organizations &&
              _vm.organizations.length > 1
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Organization",
                        "label-align": "right",
                        "label-cols": "4"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.organizations },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-select-option",
                                    { attrs: { value: null } },
                                    [_vm._v("SBS Employee")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3881720773
                        ),
                        model: {
                          value: _vm.form.organization_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "organization_id", $$v)
                          },
                          expression: "form.organization_id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isClient &&
              _vm.companies &&
              _vm.organizationCompanies.length > 1
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Company",
                        "label-align": "right",
                        "label-cols": "4"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.organizationCompanies },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-select-option",
                                    { attrs: { value: 0 } },
                                    [_vm._v("All Companies")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3986547342
                        ),
                        model: {
                          value: _vm.form.company_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "company_id", $$v)
                          },
                          expression: "form.company_id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Permission Level",
                    "label-align": "right",
                    "label-cols": "4"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.isEmployee
                        ? _vm.permissions.employee
                        : _vm.permissions.client
                    },
                    model: {
                      value: _vm.form.role_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "role_id", $$v)
                      },
                      expression: "form.role_id"
                    }
                  })
                ],
                1
              ),
              _vm.isEmployee
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Team Name",
                        "label-align": "right",
                        "label-cols": "4"
                      }
                    },
                    [
                      _c("b-input", {
                        model: {
                          value: _vm.form.teamname,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "teamname", $$v)
                          },
                          expression: "form.teamname"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEmployee
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Calendar Path",
                        "label-align": "right",
                        "label-cols": "4"
                      }
                    },
                    [
                      _c("b-input", {
                        model: {
                          value: _vm.form.calendar,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "calendar", $$v)
                          },
                          expression: "form.calendar"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "legend",
                    {
                      staticClass:
                        "col-4 bv-no-focus-ring col-form-label text-right",
                      attrs: { tabindex: "-1" }
                    },
                    [
                      _vm._v(" Role Active "),
                      _c("b-icon", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        attrs: {
                          icon: "question-circle-fill",
                          title: "Click Here for more info"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showinfo(
                              "Unchecking this box will make the users role inactive. They will still be able to sign in and access other companies they have active roles for."
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("b-form-checkbox", {
                        staticClass: "pt-2",
                        attrs: { value: 1, "unchecked-value": 0 },
                        model: {
                          value: _vm.form.activerole,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "activerole", $$v)
                          },
                          expression: "form.activerole"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "legend",
                    {
                      staticClass:
                        "col-4 bv-no-focus-ring col-form-label text-right",
                      attrs: { tabindex: "-1" }
                    },
                    [
                      _vm._v(" User Active "),
                      _c("b-icon", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        attrs: {
                          icon: "question-circle-fill",
                          title: "Click Here for more info"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showinfo(
                              "Unchecking this box will make the user completely inactive. They will not be able to login or access any companies or organizations."
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("b-form-checkbox", {
                        staticClass: "pt-2",
                        attrs: { value: 1, "unchecked-value": 0 },
                        model: {
                          value: _vm.form.active,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "active", $$v)
                          },
                          expression: "form.active"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Force Password Change",
                    "label-align": "right",
                    "label-cols": "4"
                  }
                },
                [
                  _c(
                    "b-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-form-checkbox", {
                            staticClass: "pt-2",
                            attrs: { value: 1, "unchecked-value": 0 },
                            model: {
                              value: _vm.form.forcepwchange,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "forcepwchange", $$v)
                              },
                              expression: "form.forcepwchange"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          +_vm.form.id > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "float-right",
                                  attrs: { size: "sm", variant: "danger" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.resetPassword.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Reset Password")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.canMask
                ? _c(
                    "a",
                    {
                      staticStyle: {
                        color: "teal",
                        "text-decoration": "underline"
                      },
                      attrs: { href: "/?mask=true&user_id=" + _vm.user.id }
                    },
                    [_vm._v("Open Incognito to Masquerade")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }